import React from "react";
import styled from "styled-components";
import Layout from "../../components/Layout";
import { useAlternateLangs } from "../../components/Hreflangs";
import MainContent from "../../components/MainContent";
import SEO from "../../components/Seo";
import H from "../../components/Headline";
import ContactForm from "../../components/ContactForm";

const StyledMiddle = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 0rem 2vmin;
  h1 {
    width: 100%;
  }
  .contacttextbox {
    max-width: 45%;
    margin-right: 5vmin;
  }
  @media all and (max-width: 600px) {
    .contacttextbox {
      max-width: 100%;
    }
  }
`;

// Hreflang data
const alternateLangs = useAlternateLangs(
  // replace with pages x-default
  "/en/contact"
);

  const Contact = function (props) {
    return (
      <Layout location={props.location} alternateLangs={alternateLangs}>
  
        <React.Fragment>
          <SEO
            title="Contact"
            description="Contactez-moi pour toute demande de renseignements sur l'analyse et la consultation SEO. Je vous répondrai généralement sous 24 heures."
            lang="fr"
            alternateLangs={alternateLangs}
          />
  
          <MainContent maxWidth="1000">
            <StyledMiddle>
              <H as="h1">Contact</H>
              <div className="contacttextbox">
                <p>N'hésitez pas à me contacter pour toute demande commerciale.</p>
                <p>Envoyez-moi un message et je vous répondrai généralement sous 24 heures.</p>
              </div>
              <ContactForm autoFocus showHeadline={false} formName="Formulaire de contact général" />
            </StyledMiddle>
          </MainContent>
        </React.Fragment>
  
      </Layout>
    );
  };
  
  export default React.memo(Contact);
  